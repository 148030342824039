import React from "react";
import Helmet from "react-helmet";
import Content from "../settings/Content.json";
// import Settings from "../settings/settings.json";

export const HelmetMeta = () => {
    return (
        <Helmet>
            <title>{'magpie signals'} </title>
            <meta name="author" content={Content.basics.name} />
            <meta name="description" content={Content.basics.description} />
            <meta name="keywords" content={Content.basics.keywords} />
        </Helmet>
    );
};
