import React from "react";

export const Resume = () => {
    return <div>Resume?...</div>;
};

export const PageNotFound = () => {
    return <div>off piste...</div>;
};

export const Heartbeat = () => {
    return (
        <div> 'OK' </div>      
    );
};
  