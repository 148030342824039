import React from "react";
import { Typography, Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { TextDecrypt } from "./TextDecrypt";
import txtLogo from '../logo/lgtext.svg'; 
import Content from "../../settings/Content.json";
// import { FirstName } from "../../utils/getName";

const useStyles = makeStyles((theme) => ({
    main: {
        marginTop: "auto",
        marginBottom: "auto",
        // "@media (max-width: 768px)": {
        "@media (max-width: 768px)": {
                marginLeft: theme.spacing(2),
            // marginRight: theme.spacing(2),
        },
    },
}));


// const txt1 = {    
//     fontSize: '1.0em',
//     fontFamily:'ubuntu',
//     fontWeight:300
//     color: "#000000",
// }

export const Contents = () => {
    const classes = useStyles();

    return (
        <Container component="main" className={`${classes.main}`} maxWidth="sm" >
            <Typography variant="h2" component="h3" gutterBottom>
                <TextDecrypt text={`${Content.basics.title} `} />
                <img src={txtLogo} alt="Logo" width={'75%'}/>
            </Typography>
            {/* <Typography variant="txt1" component="h2" gutterBottom noWrap> */}
            <Typography variant="h4" component="h3" gutterBottom noWrap >
                <TextDecrypt text={`${Content.basics.headline_1} `} />
                <TextDecrypt text={`${Content.basics.empty_line} `} />
                {/* <TextDecrypt text={`${Content.basics.tagline_4} `} />
                <TextDecrypt text={`${Content.basics.tagline_5} `} /> */}
            </Typography>
            <Typography variant="h6" component="h6" gutterBottom noWrap > 
                <TextDecrypt text={`${Content.basics.sub_1}`} />
                <TextDecrypt text={`${Content.basics.sub_2}`} />
                <TextDecrypt text={`${Content.basics.sub_3}`} />
                <TextDecrypt text={`${Content.basics.sub_4}`} />
                <TextDecrypt text={`${Content.basics.empty_line} `} />
                {/* <TextDecrypt text={`${Content.basics.empty_line}`} /> */}
                {/* <TextDecrypt text={`add text here ${Content.basics.sub_4}`} /> */}
            </Typography>
            <Typography variant="h4" noWrap gutterBottom>
                <TextDecrypt text={`${Content.basics.headline_3} `} />
                <TextDecrypt text={`${Content.basics.empty_line} `} />
            </Typography>

        </Container>
    );
};
